<template>
	<footer id="Contact" data-section-index="10">
		<div class="container">
			<div class="row contact">
				<div class="col-12">
					<h2 class="italic font-brown">Kontakt</h2>
				</div>
				<div class="col-12 col-xl-4 phone"
             v-if="$api.texts.phone"
             v-html="$api.texts.phone.raw"
        />
				<div class="col-12 col-md-6 col-xl-4 email">
					<strong v-if="$api.texts.email">{{ $api.texts.email.stripped }}</strong>
				</div>
				<div class="col-12 col-md-6 col-xl-4 address" v-if="$api.texts.address">
					<Icon src="map-point"/>
					<a target="_blank" :href="'https://www.google.com/maps/search/' + encodeURIComponent($api.texts.address.stripped) + '?hl=cs&source=opensearch'">
            {{ $api.texts.address.stripped }}
          </a>
				</div>
			</div>
			<div class="row">
				<div class="col-12 links">
          <p class="link-full">
            <b>Upozornění:</b> Doplněk stravy. Výrobek není náhradou pestré a vyvážené stravy.
          </p>
					<ul>
						<li>
							<router-link to="/obchodni-podminky">Obchodní podmínky</router-link>
						</li>
						<li>
							<router-link to="/reklamacni-rad">Reklamační řád</router-link>
						</li>
						<li>
							<router-link to="/gdpr">GDPR</router-link>
						</li>
            <li>
              <router-link to="/cookies">Cookies</router-link>
            </li>
						<!--<li>
							<router-link to="/doprava">Doprava</router-link>
						</li>-->
					</ul>
					<img src="../../assets/images/comgate-footer.png" alt="Comgate logo" loading="lazy">
				</div>
				<div class="col-xl-8 copyright">
					<small v-if="$api.texts.copyright" v-html="$api.texts.copyright.stripped"></small>
				</div>
				<div class="col-xl-4 credits">
					<small>
						Grafika studio <a href="https://www.boxart.agency/" target="_blank" rel="noopener">BOXART</a>,
						vytvořil <a href="https://szymsza.cz" target="_blank" rel="noopener">Jakub Szymsza</a>
					</small>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	import Icon from "./Icon";

	export default {
		name: "Footer",
		components: {Icon}
	}
</script>

<style scoped>

</style>
