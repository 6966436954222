<template>
	<div class="row other-products product-variants" :class="`product-variants-${products.length}`" v-if="products">
		<div v-for="[size, product] in products" :key="product.slug + size" class="col-md-6 product" :class="{
      'has-free-shipping': product.free_shipping,
      'sold-out': productSoldOut(product, size),
      'col-lg-4': products.length < 4,
    }">
      <div class="product-content-wrapper">
        <span class="image-link">
          <img :src="preview(product, size)" :alt="product.name" loading="lazy" :class="{ wide: !product.special_pack && product.name.includes('+'), syrup: product.is_syrup }">
          <div v-if="product.special_pack" class="promotion-badge">
            <span>
              AKCE!
            </span>
          </div>
          <div v-else-if="product.free_shipping" class="free-shipping-badge">
            <span>
              <Icon src="truck" />
            </span>
            <div>
              Doprava zdarma
            </div>
          </div>
        </span>
        <h3 class="italic font-brown">
          {{ title(product) }}
        </h3>
        <h4 class="italic font-brown" v-if="subtitle(product, size)">
          {{ subtitle(product, size) }}
        </h4>

        <Price :product="product" :key="product.slug + size" :color="color" @changeSize="() => {}" :default-size="size" :pick-size="false" :allow-buy="!productSoldOut(product, size)" />

        <div class="promotion-message" v-if="product.special_pack">
          * Akce platí do středy 28. srpna 23.00
        </div>
      </div>
		</div>
	</div>
</template>

<script>
  import Price from "../pages/Product/Price";
  import Icon from "./Icon";

	export default {
		name: "ProductVariants",
		props: {
		  product: {
        type: Object,
        required: true,
      },
      color: {
        type: String,
        required: false,
        default: "black",
      }
    },
		components: {Icon, Price},
		computed: {
			products() {
        let data = [];

        const firstSize = this.$api.productSizes[0];
        const otherSizes = this.$api.productSizes.slice(1);

        if (this.product[`price_${firstSize}_raw`] !== 0) {
          data.push([
            firstSize,
            this.product,
          ]);
        }

        for (const size of this.$api.productSizes) {
          data = [
            ...data,
            ...this.product.variants.filter((variant) => variant[`price_${size}_raw`] !== 0).map((variant) => [size, variant])
          ];
        }

        for (const size of otherSizes) {
          if (this.product[`price_${size}_raw`] !== 0) {
            data.push([
              size,
              this.product,
            ]);
          }
        }

				return data;
			},
		},
    methods: {
      preview(product, size) {
        return product[`photo_${size}`];
      },
      // also change in _laravel/app/XmlFeed.php getProductName() and possibly _laravel/app/Models/Product.php getCartName()
      title(product) {
        if (!product.is_syrup) {
          return product.name;
        }

        // e.g., only "Jahoda"
        if (!product.name.includes(" ")) {
          return `Sirup ${product.name.toLowerCase()}`;
        }

        // e.g., "Jahoda 5+1"
        return `Sirupy ${product.name.toLowerCase()}`;
      },
      subtitle(product, size) {
        return product[`size_${size}`].replace(/ /g, '\u00a0'); // replace space with NBSP
      },
      productSoldOut(product, size) {
        // TEMPORARY - mark smaller ginger sirup as sold out
        return product.sold_out || (size === 'smaller' && product.name === 'Zázvor');
      }
    },
	}
</script>