<template>
  <div :class="`buy-wrapper buy-wrapper-${color}`">
    <div class="quantity-field">
      <button @click="changeQuantity(false)">‒</button>
      <span class="quantity">
        {{ quantity }}
      </span>
      <button @click="changeQuantity">+</button>
    </div>
    <Button :color="color" class="buy-button" @click.native="addToCart" :loading="sending">
      Koupit
    </Button>
  </div>
</template>

<script>
import Button from "./Button"
import {EventBus} from "../../event-bus";

export default {
  name: "BuyButton",
  components: {Button},
  props: {
    quantity: Number,
    product: Object,
    size: String,
    color: {
      type: String,
      default: 'black'
    },
    allowBuy: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabledBuyCallback: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      sending: false,
    };
  },
  methods: {
    changeQuantity(increase = true) {
      if (increase)
        return this.$emit("changeQuantity", this.quantity + 1);

      if (this.quantity > 1)
        return this.$emit("changeQuantity", this.quantity - 1);
    },
    addToCart() {
      if (!this.allowBuy) {
        if (this.disabledBuyCallback) {
          this.disabledBuyCallback();
        }
        return;
      }

      this.sending = true;
      let apiProductObject = this.product;
      apiProductObject.value_raw = this.priceRaw;
      this.$api.addToCart(apiProductObject, this.product.type ?? "product", this.quantity, {
        size: this.size,
        ...(this.product.additionalOptions ?? {}),
      })
          .then(() => {
            EventBus.$emit("flash", "Produkt úspěšně přidán do košíku");
            EventBus.$emit("cartModified");
          })
          .catch(({ status }) => {
            EventBus.$emit(
                "flash",
                status === 409 ?
                    "Pokud chcete produkt přidat do košíku, odeberte prosím voucher" : (
                        status === 400 ?
                          "Vámi zvolený termín odeslání koliduje s termínem odeslání jiného produktu" :
                          "Produkt se nepodařilo přidat do košíku"
                    ),
                "error",
            );
          })
          .finally(() => {
            this.sending = false;
          });
    }
  }
}
</script>